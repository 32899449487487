import { Col, Row, Container, Card, Form, Button } from "react-bootstrap";
import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";

import Title from "../../components/TitleIzquierda";
import { ROUTES } from "../../constants/routes";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Contacto() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box mt-5 mb-5"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <Container>
        <Row>
          <Title titulo="Contáctanos"></Title>
        </Row>
        <Row>
          <Card
            style={{
              maxWidth: "100%!important",
              textAlign: "justify",
              backgroundColor: "#0C0B0B",
            }}
            border="dark"
            className="cardTransparent"
          >
            <Row className="g-0 align-items-center">
              <Col>
                <Card.Header style={{ color: "#fff" }}>
                  Dejanos un mensaje breve explicando tu idea o necesidad y nos
                  pondremos en contacto.
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicNombre">
                      <Form.Label style={{ color: "#fff" }}>
                        Nombre Completo
                      </Form.Label>
                      <Form.Control type="text" placeholder="Nombre Completo" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ color: "#fff" }}>Correo</Form.Label>
                      <Form.Control type="email" placeholder="Correo" />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label style={{ color: "#fff" }}>Mensaje</Form.Label>
                      <Form.Control as="textarea" rows={3} />
                    </Form.Group>

                    <NavLink to={ROUTES.CONTACTO}>
                      <Button variant="danger">Enviar</Button>
                    </NavLink>
                  </Form>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Row>
      </Container>
    </motion.div>
  );
}
