import uno from "../assets/images/card1.png";
import dos from "../assets/images/card2.png";
import tres from "../assets/images/card3.png";
import { IHistoria } from "../models/interfaces/IHistoria";

export const historiaData: IHistoria[] = [
  //Historia 1
  {
    id: 1,
    img: `${uno}`,
    titulo: "Card title",
    cuerpo:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    cuerpoModal: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec
          sagittis aliquam malesuada bibendum arcu. Tellus at urna condimentum
          mattis pellentesque id. Elementum pulvinar etiam non quam lacus
          suspendisse.
        </p>
        <p>
          Condimentum id venenatis a condimentum vitae sapien pellentesque
          habitant. Ut lectus arcu bibendum at varius vel pharetra vel turpis.
          Tincidunt id aliquet risus feugiat in ante. Ipsum dolor sit amet
          consectetur adipiscing elit pellentesque habitant morbi. Risus commodo
          viverra maecenas accumsan lacus. Tincidunt nunc pulvinar sapien et
          ligula ullamcorper. Mollis nunc sed id semper risus in hendrerit.
          Turpis massa sed elementum tempus egestas sed sed risus pretium. Ipsum
          dolor sit amet consectetur. Amet commodo nulla facilisi nullam
          vehicula ipsum a. Volutpat sed cras ornare arcu dui vivamus. Convallis
          aenean et tortor at risus viverra adipiscing. Commodo ullamcorper a
          lacus vestibulum.`,
        </p>
      </>
    ),
  },

  //Historia 2
  {
    id: 2,
    img: `${dos}`,
    titulo: "Card title",
    cuerpo:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    cuerpoModal: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec
          sagittis aliquam malesuada bibendum arcu. Tellus at urna condimentum
          mattis pellentesque id. Elementum pulvinar etiam non quam lacus
          suspendisse.
        </p>
        <p>
          Condimentum id venenatis a condimentum vitae sapien pellentesque
          habitant. Ut lectus arcu bibendum at varius vel pharetra vel turpis.
          Tincidunt id aliquet risus feugiat in ante. Ipsum dolor sit amet
          consectetur adipiscing elit pellentesque habitant morbi. Risus commodo
          viverra maecenas accumsan lacus. Tincidunt nunc pulvinar sapien et
          ligula ullamcorper. Mollis nunc sed id semper risus in hendrerit.
          Turpis massa sed elementum tempus egestas sed sed risus pretium. Ipsum
          dolor sit amet consectetur. Amet commodo nulla facilisi nullam
          vehicula ipsum a. Volutpat sed cras ornare arcu dui vivamus. Convallis
          aenean et tortor at risus viverra adipiscing. Commodo ullamcorper a
          lacus vestibulum.`,
        </p>
      </>
    ),
  },

  //Historia 3
  {
    id: 3,
    img: `${tres}`,
    titulo: "Card title",
    cuerpo:
      "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
    cuerpoModal: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec
          sagittis aliquam malesuada bibendum arcu. Tellus at urna condimentum
          mattis pellentesque id. Elementum pulvinar etiam non quam lacus
          suspendisse.
        </p>
        <p>
          Condimentum id venenatis a condimentum vitae sapien pellentesque
          habitant. Ut lectus arcu bibendum at varius vel pharetra vel turpis.
          Tincidunt id aliquet risus feugiat in ante. Ipsum dolor sit amet
          consectetur adipiscing elit pellentesque habitant morbi. Risus commodo
          viverra maecenas accumsan lacus. Tincidunt nunc pulvinar sapien et
          ligula ullamcorper. Mollis nunc sed id semper risus in hendrerit.
          Turpis massa sed elementum tempus egestas sed sed risus pretium. Ipsum
          dolor sit amet consectetur. Amet commodo nulla facilisi nullam
          vehicula ipsum a. Volutpat sed cras ornare arcu dui vivamus. Convallis
          aenean et tortor at risus viverra adipiscing. Commodo ullamcorper a
          lacus vestibulum.`,
        </p>
      </>
    ),
  },
];
