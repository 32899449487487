import { FloatingWhatsApp } from "react-floating-whatsapp";

import polo from "../../assets/images/polo.jpg";

export default function WhatsApp() {
  return (
    <FloatingWhatsApp
      accountName="Aleante"
      phoneNumber="+5213122008523"
      darkMode={true}
      chatMessage="Hola, esta es una prueba del whats app"
      statusMessage="Horario de respuesta de 8am - 5pm"
      avatar={polo}
      allowClickAway={true}
      messageDelay={0}
      placeholder="Mensaje"
      onSubmit={function noRefCheck() {}}
    />
  );
}
