import { Row, Container, Card, CardGroup } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import Title from "../TitleDerecha";
import { serviciosData } from "../../constants/serviciosData";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "swiper/css";
import "swiper/css/pagination";

import "../../styles/components/Servicios.scss";

export default function Servicios() {
  return (
    <>
      <Container>
        <Row>
          <Title titulo="Servicios"></Title>
        </Row>
      </Container>
      <div className="servicios">
        <Row className="cardBack">
          <Swiper
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
            direction={"horizontal"}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            // loop={true}
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {serviciosData.map((data) => (
              <div key={data.id}>
                <SwiperSlide>
                  <CardGroup>
                    <Card className="cardTransparent m-5 cardEvent ">
                      <Card.Header className="textHeader">
                        {data.titulo}
                      </Card.Header>
                      <Card.Body className="textBody">
                        <Card.Text className="text">{data.texto}</Card.Text>
                      </Card.Body>
                    </Card>
                  </CardGroup>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </Row>
      </div>
    </>
  );
}
