import { IServicio } from "../models/interfaces/IServicio";

export const serviciosData: IServicio[] = [
  //Topografía de minas
  {
    titulo: "Topografía de minas.",
    texto:
      "Realizamos un control integral de topografía en mina, que incluye actualizaciones de tajos, caminos, insitus, rellenos, marcación de voladuras y actualización de voladuras. Además, llevamos a cabo el levantamiento de inventarios, todo ello acompañado del proceso de la información y el reporte de avances.",
    alt: "Minas",
    id: 1,
  },

  //Topografía de obra
  {
    titulo: "Topografía de obra.",
    texto:
      "Contamos con experiencia tanto como ejecutores de trazo, como en la supervisión topográfica de obras. Nos encargamos de llevar un control exhaustivo desde el inicio del proyecto, incluyendo excavaciones, cimentación, volúmenes, estructuras y montajes. Nos aseguramos de que los proyectos se ejecuten con precisión y cumplan con los estándares de ingeniería requeridos.",
    alt: "Obra",
    id: 2,
  },

  //Topografía mecánica
  {
    titulo: "Topografía mecánica.",
    texto:
      "Brindamos el servicio de topografía mecánica para plantas de procesos. Contamos con equipos especializados de alta precisión para llevar a cabo los mantenimientos mecánicos necesarios, como alineaciones y nivelaciones. Nuestro equipo está capacitado para trabajar con rodillos, mandriles, bandas, ventiladores y cualquier elemento que forme parte de la línea de producción. Utilizamos equipos Brunson y niveles con micrómetro que nos permiten trabajar con una precisión de décimas de milímetro en las alineaciones y nivelaciones.",
    alt: "Mecánica",
    id: 3,
  },

  //Ingenierias
  {
    titulo: "Ingenierias.",
    texto: (
      <>
        <p>
          Desarrollamos proyectos de ingeniería a nivel industrial y comercial,
          brindando soluciones en el diseño de proyectos de obra civil, mecánica
          y eléctrica. Nos enfocamos en proporcionar al cliente la propuesta que
          mejor se ajuste a sus necesidades. Realizamos los cálculos de
          estructuras, cimentaciones y sistemas eléctricos basados en los
          diseños propuestos. Nos aseguramos de que nuestro trabajo se realice
          con cuidado y ofrecemos garantías al cliente. Además, proporcionamos
          los reportes necesarios que respaldan y garantizan la precisión de la
          información.
        </p>
        <p>
          Realizamos dictámenes para el área civil y eléctrica que cumplen con
          los requisitos legales establecidos por las dependencias encargadas de
          la liberación de obras y la garantía de la seguridad de las
          instalaciones.
        </p>
      </>
    ),
    alt: "Ingenierias",
    id: 4,
  },

  //Limpieza industrial
  {
    titulo: "Limpieza industrial.",
    texto: (
      <>
        <p>
          Nuestos serivicos de limpieza industrial buscan dar soluciones a los
          temas del cuidad dia dia de las isntalaciones, teniendo alcance en:
        </p>
        <ul>
          <li>Identificación y pintado de tuberías de acuerdo a norma</li>
          <li>limpieza de bandas transportadoras</li>
          <li>limpieza de pisos en áreas de producción</li>
          <li>Clasificación de desechos.</li>
          <li>Recolección de chatarra.</li>
        </ul>
      </>
    ),
    alt: "Limpieza",
    id: 5,
  },
];
