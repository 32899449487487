import { IEquipo } from "../models/interfaces/IEquipo";

import uno from "../assets/images/equipo1.png";
import dos from "../assets/images/equipo2.png";
import tres from "../assets/images/equipo3.png";
import cuatro from "../assets/images/equipo4.png";
import cinco from "../assets/images/equipo5.png";
import seis from "../assets/images/equipo6.png";

export const equipoData: IEquipo[] = [
  //Equipo 1
  {
    img: `${uno}`,
    texto: (
      <>
        <p>
          <strong>Lorem ipsum dolor sit amet</strong>
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </>
    ),
    alt: "equipo1",
    idEquipo: 1,
    width: "100%",
  },

  //Equipo 2
  {
    img: `${dos}`,
    texto: (
      <>
        <p>
          <strong>Lorem ipsum dolor sit amet</strong>
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </>
    ),
    alt: "equipo2",
    idEquipo: 2,
    width: "73.51%",
  },

  //Equipo 3
  {
    img: `${tres}`,
    texto: (
      <>
        <p>
          <strong>Lorem ipsum dolor sit amet</strong>
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </>
    ),
    alt: "equipo3",
    idEquipo: 3,
    width: "73.51%",
  },

  //Equipo 4
  {
    img: `${cuatro}`,
    texto: (
      <>
        <p>
          <strong>Lorem ipsum dolor sit amet</strong>
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </>
    ),
    alt: "equipo4",
    idEquipo: 4,
    width: "100%",
  },

  //Equipo 5
  {
    img: `${cinco}`,
    texto: (
      <>
        <p>
          <strong>Lorem ipsum dolor sit amet</strong>
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </>
    ),
    alt: "equipo5",
    idEquipo: 5,
    width: "62.69%",
  },

  //Equipo 6
  {
    img: `${seis}`,
    texto: (
      <>
        <p>
          <strong>Lorem ipsum dolor sit amet</strong>
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </>
    ),
    alt: "equipo6",
    idEquipo: 6,
    width: "80.78%",
  },
];
