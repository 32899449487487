import { useEffect, useState } from "react";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import { ROUTES } from "../../constants/routes";

import aleante from "../../assets/images/aleante.svg";
import fondo1 from "../../assets/images/fondoParallax1.png";
import fondo2 from "../../assets/images/fondoParallax2.png";
import fondo3 from "../../assets/images/fondoParallax3.png";
import fondo4 from "../../assets/images/fondoParallax4.png";
import fondo5 from "../../assets/images/fondoParallax5.png";

import "../../styles/components/HeaderMain.scss";

export default function HeaderMain() {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <>
      <Navbar
        className={navbar ? "navmenu scroll" : "navmenu"}
        expand="lg"
        variant="dark"
        fixed="top"
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Brand as={Link} to={ROUTES.ROOT}>
            <img src={aleante} alt="Logo aleante" width={"100%"} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <div className="navmenu__nav-items">
              <Nav>
                <Nav.Link as={NavLink} to={ROUTES.ROOT}>
                  Inicio
                </Nav.Link>
                <Nav.Link as={NavLink} to={ROUTES.TEAMWORK}>
                  Equipo
                </Nav.Link>
                <Nav.Link as={NavLink} to={ROUTES.OFERTA}>
                  Oferta de trabajo
                </Nav.Link>
                <Nav.Link as={NavLink} to={ROUTES.CONTACTO}>
                  Contacto
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ParallaxBanner style={{ aspectRatio: "2 / 1" }} className="imgParallax ">
        <ParallaxBannerLayer speed={-15}>
          <img src={fondo1} width={"100%"} alt="Sahara Desert landscape" />
        </ParallaxBannerLayer>
        <ParallaxBannerLayer speed={-15}>
          <img src={fondo2} width={"100%"} alt="Sahara Desert landscape" />
        </ParallaxBannerLayer>
        <ParallaxBannerLayer speed={-15}>
          <img src={fondo3} width={"100%"} alt="Sahara Desert landscape" />
        </ParallaxBannerLayer>
        <ParallaxBannerLayer speed={-25}>
          <img src={fondo4} width={"100%"} alt="Sahara Desert landscape" />
        </ParallaxBannerLayer>
        <ParallaxBannerLayer speed={-30}>
          <img src={fondo5} width={"100%"} alt="Sahara Desert landscape" />
        </ParallaxBannerLayer>
      </ParallaxBanner>
    </>
  );
}
