import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";

import { ROUTES } from "../../constants/routes";

import aleante from "../../assets/images/aleante.svg";
import "../../styles/components/Header.scss";

export default function Header() {
  const { pathname } = useLocation();

  return (
    <Navbar
      className="navmenu px-3"
      bg="primary"
      expand="lg"
      variant="dark"
      sticky="top"
      collapseOnSelect
    >
      <Container fluid>
        <Navbar.Brand as={Link} to={ROUTES.ROOT}>
          <img src={aleante} alt="Logo aleante" width={"100%"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <div className="navmenu__nav-items">
            <Nav>
              <Nav.Link as={NavLink} to={ROUTES.ROOT}>
                Inicio
              </Nav.Link>
              <Nav.Link as={NavLink} to={ROUTES.TEAMWORK}>
                Equipo
              </Nav.Link>
              <Nav.Link as={NavLink} to={ROUTES.OFERTA}>
                Oferta de trabajo
              </Nav.Link>
              <Nav.Link as={NavLink} to={ROUTES.CONTACTO}>
                Contacto
              </Nav.Link>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
