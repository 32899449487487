import { Col } from "react-bootstrap";

import { IEmpleado } from "../models/interfaces/IEmpleado";

import StarIcon from "@mui/icons-material/Star";

import polo from "../assets/images/poloJSerio.jpg";
import beto from "../assets/images/betoSerio.jpg";
import isis from "../assets/images/isisSeria.jpg";
import elsy from "../assets/images/elsySerio.jpg";
import luis from "../assets/images/luisSerio.jpeg";
import enrique from "../assets/images/enriqueSerio.jpg";
import johana from "../assets/images/johanaSerio.jpg";
import alejandro from "../assets/images/alejandroSerio.jpg";

import poloAlt from "../assets/images/poloJChamba.jpg";
import betoAlt from "../assets/images/betoChamba.jpg";
import isisAlt from "../assets/images/isisChamba.jpg";
import elsyAlt from "../assets/images/elsyChamba.jpg";
import luisAlt from "../assets/images/luisChamba.jpeg";
import enriqueAlt from "../assets/images/enriqueChamba.jpg";
import johanaAlt from "../assets/images/johanaChamba.jpg";
import alejandroAlt from "../assets/images/alejandroChamba.jpg";

export const teamworkData: IEmpleado[] = [
  //Polo Maldonado
  {
    nombreCompleto: "Polo Maldonado",
    nombre: "Marco Polo",
    apellido: "Maldonado Rayas",
    descripcion:
      "Apasionado en la re-ingeniería de procesos, con alta experiencia en organización de empresas y negociación efectiva, mi principal función radica en la supervisión de proyectos de innovación tecnológica y en la detección oportuna de las necesidades cambiantes del mercado a través de la mezcla de tecnología, conocimientos e innovación.",
    lema: "La estrategia en los proyectos la aporta usted, la ingeniería de proyectos, MSW.",
    titulo: "Director administrativo",
    alt: "Polo Maldonado",
    src: `${polo}`,
    estudio: "Ingeniero Químico Metalúrgico",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>
              Administración de proyectos | Mejora Continua | Negociación |
              Planificación Estratégica | Minería | Ingeniería de Procesos
            </p>
          </div>
        </Col>
      </>
    ),
    id: 1,
    srcAlt: `${poloAlt}`,
    filter: "sepia(80%)",
  },

  //Alberto Sánchez
  {
    nombreCompleto: "Alberto Sánchez",
    nombre: "Alberto",
    apellido: "Sánchez Cano",
    descripcion: "Me considero una persona responsable, pro activa y honesta.",
    lema: "Solo yo puedo cambiar mi vida. Nadie puede hacerlo por mí",
    titulo: "Director operativo",
    alt: "Alberto Sánchez",
    src: `${beto}`,
    estudio: "Licenciada en Administración",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Siempre disponible para aprender y resolver problemas.</p>
          </div>
        </Col>
      </>
    ),
    id: 2,
    srcAlt: `${betoAlt}`,
    filter: "sepia(80%)",
  },

  //Isis Segundo
  {
    nombreCompleto: "Isis Segundo",
    nombre: "Isis Judith",
    apellido: "Segundo Lauriano",
    descripcion: "Me considero una persona responsable, pro activa y honesta.",
    lema: "Solo yo puedo cambiar mi vida. Nadie puede hacerlo por mí",
    titulo: "Administrador contable.",
    alt: "Isis Segundo",
    src: `${isis}`,
    estudio: "Licenciada en Administración",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Siempre disponible para aprender y resolver problemas.</p>
          </div>
        </Col>
      </>
    ),
    id: 3,
    srcAlt: `${isisAlt}`,
    filter: "sepia(80%)",
  },

  //Elsy Bravo
  {
    nombreCompleto: "Elsy Bravo",
    nombre: "Elsy Yacaranday",
    apellido: "Bravo Michel",
    descripcion: "Me considero una persona dedicada, responsable y proactiva.",
    lema: "Sé el cambio que quieres ver en el mundo",
    titulo: "Administración y recursos humanos.",
    alt: "Elsy Bravo",
    src: `${elsy}`,
    estudio: "Licenciada en Administración",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Siempre disponible para aprender y resolver problemas.</p>
          </div>
        </Col>
      </>
    ),
    id: 4,
    srcAlt: `${elsyAlt}`,
    filter: "sepia(80%)",
  },

  //Luis Muñiz
  {
    nombreCompleto: "Luis Muñiz",
    nombre: "Luis Alberto",
    apellido: "Muñiz Zavaleta",
    descripcion:
      "Me considero una persona autodidacta, todo el tiempo trato de aprender nuevas tecnologías. Me gusta bastante la programación, en especial el desarrollo web y móvil.",
    lema: "Nunca es suficiente, siempre existe algo nuevo por aprender",
    titulo: "Intern/Trainee",
    alt: "Luis Muñiz",
    src: `${luis}`,
    estudio: "Ingeniero en Telemática",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Siempre disponible para aprender y resolver problemas.</p>
          </div>
        </Col>
      </>
    ),
    id: 5,
    srcAlt: `${luisAlt}`,
    filter: "sepia(80%)",
  },

  //Luis Estrada
  {
    nombreCompleto: "Luis Estrada",
    nombre: "Luis Enrique",
    apellido: "Estrada Díaz",
    descripcion:
      "Soy capaz, íntegro, responsable, respetuoso y ambicioso por aprender",
    lema: "Propongo, me esfuerzo y lo logro",
    titulo: "Topografo",
    alt: "Luis Estrada",
    src: `${enrique}`,
    estudio: "Ingeniero en Topografía",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>
              Analista topográfico | Desarrollador de trabajos topográficos |
              Cumplo todas mis metas que me propongo
            </p>
          </div>
        </Col>
      </>
    ),
    id: 6,
    srcAlt: `${enriqueAlt}`,
    filter: "sepia(80%)",
  },

  //Johana Mejía
  {
    nombreCompleto: "Johana Mejía",
    nombre: "Johana Jaqueline",
    apellido: "Mejía Ramírez",
    descripcion:
      "Me considero una persona autodidacta, todo el tiempo trato de aprender nuevas tecnologías. Me gusta bastante la programación, en especial el desarrollo web y móvil.",
    lema: "Nunca es suficiente, siempre existe algo nuevo por aprender",
    titulo: "Intern/Trainee",
    alt: "Johana Mejía",
    src: `${johana}`,
    estudio: "Ingeniero en Telemática",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Siempre disponible para aprender y resolver problemas.</p>
          </div>
        </Col>
      </>
    ),
    id: 7,
    srcAlt: `${johanaAlt}`,
    filter: "sepia(80%)",
  },

  //Alejandro Zapien
  {
    nombreCompleto: "Alejandro Zapien",
    nombre: "Alejandro",
    apellido: "Zapien Nicolas",
    descripcion:
      "Me considero una persona autodidacta, todo el tiempo trato de aprender nuevas tecnologías. Me gusta bastante la programación, en especial el desarrollo web y móvil.",
    lema: "Nunca es suficiente, siempre existe algo nuevo por aprender",
    titulo: "Intern/Trainee",
    alt: "Alejandro Zapien",
    src: `${alejandro}`,
    estudio: "Ingeniero en Telemática",
    reconocimiento: (
      <>
        <Col lg={1}>
          <StarIcon color="primary" />
        </Col>
        <Col lg={11}>
          <div className="text-white text-justify">
            <p>Siempre disponible para aprender y resolver problemas.</p>
          </div>
        </Col>
      </>
    ),
    id: 8,
    srcAlt: `${alejandroAlt}`,
    filter: "sepia(80%)",
  },
];
