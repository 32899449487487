import { useState, useEffect } from "react";
import { Row, Container, Card, Col, Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Title from "../TitleDerecha";
import { IHistoria } from "../../models/interfaces/IHistoria";
import { historiaData } from "../../constants/historiaData";
import HistoriaModal from "../../components/modals/HistoriaModal";

import "../../styles/components/Historias.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Historias() {
  const [estadoModal, setEstadoModal] = useState<boolean>(false);
  const [currentInfo, setCurrentInfo] = useState<IHistoria>({
    titulo: "",
    cuerpo: "",
    img: ``,
    id: 0,
  });

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <Container>
        <Row>
          <Title titulo="Historias de éxito"></Title>
        </Row>
        <Row>
          <Col>
            Ser líder nacional en servicios de ingeniería para empresas con
            crecimiento organizacional sustentable, a través de la capacitación
            y participación activa de nuestro personal.
          </Col>
        </Row>
        <Row>
          <Swiper
            breakpoints={{
              576: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            spaceBetween={30}
            navigation={true}
            loop={true}
            modules={[Navigation]}
            className="mySwiper mt-3 mb-3"
          >
            {historiaData.map((data) => (
              <div key={data.id}>
                <SwiperSlide>
                  <Card className="historia m-1">
                    <Card.Img variant="top" src={data.img} />
                    <Card.Body className="historia_body ">
                      <Card.Title>{data.titulo}</Card.Title>
                      <Card.Text>{data.cuerpo}</Card.Text>
                      <Button
                        variant="primary"
                        className="historia_button"
                        onClick={() => {
                          setCurrentInfo(data);
                          setEstadoModal(true);
                        }}
                      >
                        Leer mas
                      </Button>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </Row>

        <HistoriaModal
          estadoModal={estadoModal}
          cambiarEstadoModal={setEstadoModal}
          infoHistoria={currentInfo}
        />
      </Container>
    </motion.div>
  );
}
