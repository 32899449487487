export const carreraData = [
  {
    id: "topografo",
    label: "Ingenierio Topografo y Geologo",
  },
  {
    id: "industrial",
    label: "Ingeniero Industrial",
  },
  {
    id: "civil",
    label: "Ingeniero Civil",
  },
];
