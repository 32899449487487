import { Row, Container, Col, Image } from "react-bootstrap";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Title from "../TitleIzquierda";
import { equipoData } from "../../constants/equipoData";

import "../../styles/components/Equipo.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Equipos() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box background"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <Container>
        <Row>
          <Title titulo="Equipos e Instrumentación"></Title>
        </Row>
        <Row>
          <p>
            Nuestro objetivo es ofrecer a nuestros clientes los mejores
            servicios de ingeniería y supervisión tecnificada, cumpliendo con
            los requerimientos de ejecución de obras de manera segura, eficiente
            y oportuna. Nos esforzamos por entregar servicios con el más alto
            índice de calidad.
          </p>
        </Row>
        <Row className="equipo" xl="4" lg="4" md="3" sm="2" xs="1">
          {equipoData.map((data) => (
            <Col className="fondoCard m-1" key={data.idEquipo}>
              <Image
                src={data.img}
                alt={data.alt}
                width={data.width}
                className="imageCard"
              ></Image>
              <div className="m-2 textCard">{data.texto}</div>
            </Col>
          ))}
        </Row>
      </Container>
    </motion.div>
  );
}
