import { Col, Row, Container, Card } from "react-bootstrap";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Title from "../TitleIzquierda";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function InfoEmpresa() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <Container>
        <Row>
          <Title titulo="¿Quiénes somos?"></Title>
        </Row>
        <Row>
          <Card
            style={{
              maxWidth: "100%!important",
              textAlign: "justify",
            }}
            border="0"
            className="cardTransparent"
          >
            <Row className="g-0 align-items-center">
              <Col md="12">
                <Card.Body>
                  <Card.Text>
                    Somos una empresa joven, creada en el año 2018, gracias a la
                    visión de resolver fuertes necesidades que el sector
                    minero-siderúrgico está presentando. Nos hemos creado y
                    fortalecido con la ejecución de proyectos de ingeniería en
                    el sector minero-siderúrgico, donde a lo largo del tiempo
                    hemos ido creciendo y ofreciendo una mayor variedad de
                    soluciones en este ámbito.
                  </Card.Text>
                  <Card.Text>
                    Hoy en día ejecutamos proyectos de topografía en mina y
                    topografía industrial, en el área de mantenimiento mecánico
                    de diversas plantas en el país. Realizamos proyectos de
                    diseño y cálculo de obra mecánica, civil y eléctrica, lo
                    cual nos posiciona como una empresa de servicios
                    especializados capaz de hacer frente a cualquier reto que se
                    presente. De la mano de dar soluciones en el ambiente
                    minero-siderúrgico, desarrollamos una división de servicios
                    especializados en limpieza industrial, apoyando al
                    mantenimiento y limpieza general de áreas industriales,
                    limpieza de bandas, pintura e identificación de tuberías, y
                    trabajos de limpieza en alturas.
                  </Card.Text>
                  <Card.Text>
                    Todas estas fortalezas nos han posicionado y ayudado a
                    crecer a través del tiempo, y seguimos buscando una mejora
                    continua para mantenernos como referentes de calidad y buen
                    servicio.
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Row>
      </Container>
    </motion.div>
  );
}
