import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HeaderMain from "./components/Header/HeaderMain";
import Loading from "./components/Loading/Loading";
import Home from "./pages/Home/Home";
import Contacto from "./pages/Contacto/Contacto";
import TeamWork from "./pages/TeamWork/TeamWork";
import Oferta from "./pages/Oferta/Oferta";
import WhatsApp from "./components/WhatsApp/WhatsApp";
import { ROUTES } from "./constants/routes";

import "./App.css";

function App() {
  const [cargar, setCargar] = useState<boolean>(false);
  const [render, setRender] = useState<any>(null);
  let location = useLocation();

  useEffect(() => {
    setCargar(true);
    setRender(location.pathname !== ROUTES.ROOT ? <Header /> : <HeaderMain />);
    setTimeout(() => {
      setCargar(false);
    }, 500);
  }, [location.pathname]);

  const fondo = () => {
    return {
      minHeight: "100vh",
    };
  };

  return (
    <>
      {cargar && <Loading />}
      <main
        className="d-flex flex-column justify-content-between"
        style={fondo()}
      >
        {render}
        <div className="flex-grow-1 background">
          <Routes>
            <Route path={ROUTES.TEAMWORK} element={<TeamWork />} />
            <Route path={ROUTES.OFERTA} element={<Oferta />} />
            <Route path={ROUTES.CONTACTO} element={<Contacto />} />
            <Route path={ROUTES.ROOT} element={<Home />} />
          </Routes>
        </div>
        <WhatsApp />
        <Footer />
      </main>
    </>
  );
}

export default App;
