import { Col, Row, Nav } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

import aleante from "../../assets/images/aleanteFooter.svg";
import "../../styles/components/Footer.scss";
import { ANCLAS } from "../../constants/routes";

var today = new Date();
var year = today.getFullYear();

export default function Footer() {
  return (
    <div>
      <Row className="footer bg-primary ">
        <Col
          xs="6"
          sm="6"
          md="7"
          lg="7"
          xl="7"
          className="mt-1 mb-1  d-flex align-items-center imagen"
        >
          <img src={aleante} alt="Logo" className="labLogo" width={100} />
        </Col>
        <Col
          xs="6"
          sm="6"
          md="5"
          lg="5"
          xl="5"
          className="d-grid align-items-center align-content-center mt-2 enlaces"
        >
          <div className=" d-flex align-items-center">
            <LocationOnIcon className="me-2" sx={{ color: "red" }} />
            <Nav.Link
              href={ANCLAS.MAPA}
              className="text-white text-decoration-none"
              target="_blank"
            >
              Calzada del campesino 265, Colonia San Pablo. Colima, Col. C.P.
              28060
            </Nav.Link>
          </div>
          <div className="text-white d-flex align-items-center mt-2">
            <EmailIcon className="me-2" sx={{ color: "white" }} />{" "}
            asistencia@aleante.com.mx
          </div>
        </Col>
      </Row>
      <div className="text-center p-2" style={{ backgroundColor: "#E1DADA" }}>
        MSW Tecnologías S.A. de C.V. {year}
      </div>
    </div>
  );
}
