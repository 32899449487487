import Principal from "../../components/Home/InfoEmpresa";
import Servicios from "../../components/Home/Servicios";
import Equipos from "../../components/Home/Equipos";
import Historias from "../../components/Home/Historias";
import Logros from "../../components/Home/Logros";

import "../../styles/components/Home.scss";

export default function Home() {
  return (
    <div>
      <Principal />
      <Servicios />
      <Equipos />
      <Historias />
      <Logros />
    </div>
  );
}
