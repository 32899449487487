import { Col, Container, Modal, Row } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { IHistoria } from "../../models/interfaces/IHistoria";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#fff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

export default function HistoriaModal(props: historiaModalProps) {
  const { estadoModal, cambiarEstadoModal, infoHistoria } = props;
  return (
    <Modal
      show={estadoModal}
      onHide={() => cambiarEstadoModal(false)}
      dialogClassName="modal-100w"
      className="modal-100w"
      size="xl"
    >
      <ThemeProvider theme={theme}>
        <Modal.Header closeButton closeVariant="white"></Modal.Header>
        <Modal.Body className="show-grid p-0 .bg-secondary">
          <Container className="p-0">
            <Row>
              <Col lg={6} className="p-4">
                <Row>
                  <Col>
                    <h1 className="text-white">{infoHistoria.titulo}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-white text-justify">
                      {infoHistoria.cuerpoModal}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <img
                  src={infoHistoria.img}
                  alt="imagen"
                  className="w-100 h-100"
                ></img>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </ThemeProvider>
    </Modal>
  );
}

export type historiaModalProps = {
  estadoModal: boolean;
  cambiarEstadoModal: Function;
  infoHistoria: IHistoria;
};
