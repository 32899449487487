import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { Col, Row, Container, Card, Form, Button } from "react-bootstrap";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { ROUTES } from "../../constants/routes";
import TitleTabs from "../../components/TitleTabs";
import { carreraData } from "../../constants/carreraData";

import trabajadores from "../../assets/images/trabajadores.png";

import "../../styles/components/Oferta.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Oferta() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <div className="oferta">
      <Container>
        <motion.div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
          <Row>
            <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className="p-2">
              <Row className="text-white">
                <TitleTabs titulo="¡Forma parte de nuestro Equipo de trabajo!"></TitleTabs>
              </Row>
              <Row className="text-white">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p>
                  Puedes realizar tus practicas profesionales con nosotos tu CV
                </p>
              </Row>
              <Row>
                <img src={trabajadores} alt="Logo" />
              </Row>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              className="p-2 bg-dark"
            >
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3 " controlId="formBasicNombre">
                    <Form.Label className="text-white">
                      Nombre Completo
                    </Form.Label>
                    <Form.Control type="text" placeholder="Nombre Completo" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-white">Correo</Form.Label>
                    <Form.Control type="email" placeholder="Correo" />
                  </Form.Group>

                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label className="text-white">
                      Adjunta tu CV
                    </Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>

                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label className="text-white">Carrera Afin</Form.Label>
                    {["radio"].map((type: any) => (
                      <div key={`${type}`} className="mb-3">
                        {carreraData.map((data: any) => (
                          <Form.Check
                            className="text-white"
                            label={data.label}
                            name="group1"
                            type={type}
                            id={data.id}
                          />
                        ))}
                        <div className="d-flex">
                          <Form.Check
                            className="text-white"
                            label="Otro"
                            type={type}
                            name="group1"
                            id={`${type}-4`}
                          />
                          <Form.Control
                            type="text"
                            className="ms-2"
                            placeholder="Otra carrera"
                          />
                        </div>
                      </div>
                    ))}
                  </Form.Group>

                  <NavLink to={ROUTES.OFERTA}>
                    <Button variant="danger" type="submit" size="lg">
                      Postularme
                    </Button>
                  </NavLink>
                </Form>
              </Card.Body>
            </Col>
          </Row>
        </motion.div>
      </Container>
    </div>
  );
}
