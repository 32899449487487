export const logrosData = [
  {
    titulo: "logro 1",
    dato: "80 +",
    id: 1,
  },
  {
    titulo: "logro 2",
    dato: "200 +",
    id: 2,
  },
  {
    titulo: "logro 3",
    dato: "10 +",
    id: 3,
  },
  {
    titulo: "logro 4",
    dato: "1000 +",
    id: 4,
  },
];
