import { useState, useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Row, Container, Image, Col } from "react-bootstrap";

import Title from "../../components/TitleIzquierda";
import { teamworkData } from "../../constants/teamworkData";
import TeamModal from "../../components/modals/TeamModal";
import { IEmpleado } from "../../models/interfaces/IEmpleado";

import "../../styles/components/TeamWork.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Teamwork() {
  const [estadoModal, setEstadoModal] = useState<boolean>(false);
  const [currentInfo, setCurrentInfo] = useState<IEmpleado>({
    nombre: "",
    titulo: "",
    alt: "",
    srcAlt: ``,
    src: ``,
    id: 0,
  });

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <Container>
        <Row className="mb-3">
          <Title titulo="Equipo"></Title>
        </Row>
        <Row className="Teamwork" xl="5" lg="4" md="3" sm="2" xs="1">
          {teamworkData.map((data: IEmpleado) => (
            <Col className="img">
              <Image
                src={data.src}
                alt={data.alt}
                style={{
                  filter: `${data.filter}`,
                  backgroundImage: `${data.gradient}`,
                }}
                width={200}
                className="imgOriginal "
                onClick={() => {
                  setCurrentInfo(data);
                  setEstadoModal(true);
                }}
              ></Image>
              <Image
                src={data.src}
                alt={data.alt}
                width={200}
                className="imgAlt"
                onClick={() => {
                  setCurrentInfo(data);
                  setEstadoModal(true);
                }}
              ></Image>
              <h3 className="nombre">{data.nombreCompleto}</h3>
              <h4 className="text mb-4">{data.titulo}</h4>
            </Col>
          ))}
        </Row>

        <TeamModal
          estadoModal={estadoModal}
          cambiarEstadoModal={setEstadoModal}
          infoEmpleado={currentInfo}
        />
      </Container>
    </motion.div>
  );
}
