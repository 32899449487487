import { Col, Container, Modal, Row } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { IEmpleado } from "../../models/interfaces/IEmpleado";

import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#fff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

export default function TeamModal(props: teamModalProps) {
  const { estadoModal, cambiarEstadoModal, infoEmpleado } = props;
  return (
    <Modal
      show={estadoModal}
      onHide={() => cambiarEstadoModal(false)}
      dialogClassName="modal-100w"
      className="modal-100w"
      size="xl"
    >
      <ThemeProvider theme={theme}>
        <Modal.Header closeButton closeVariant="white"></Modal.Header>
        <Modal.Body className="show-grid p-0 .bg-secondary">
          <Container className="p-0">
            <Row>
              <Col lg={6} className="p-4">
                <Row>
                  <Col>
                    <h1 className="text-white">{infoEmpleado.nombre}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 className="text-white">{infoEmpleado.apellido}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className="Titulo ">{infoEmpleado.titulo}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-white text-justify">
                      {infoEmpleado.descripcion}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="quote text-white text-center ">
                    <p className="mt-3 mb-3 ">{infoEmpleado.lema}</p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={1}>
                    <SchoolIcon color="primary" />
                  </Col>
                  <Col className="text-white text-justify">
                    <p>{infoEmpleado.estudio}</p>
                  </Col>
                </Row>
                <Row>{infoEmpleado.reconocimiento}</Row>
              </Col>
              <Col lg={6}>
                <img
                  src={infoEmpleado.srcAlt}
                  alt="imagen"
                  className="w-100 h-100"
                ></img>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </ThemeProvider>
    </Modal>
  );
}

export type teamModalProps = {
  estadoModal: boolean;
  cambiarEstadoModal: Function;
  infoEmpleado: IEmpleado;
};
