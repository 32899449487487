export const ROUTES = {
  ROOT: "/",
  HOME: "/home",
  CONTACTO: "/Contacto",
  TEAMWORK: "/TeamWork",
  OFERTA: "/Oferta",
  LOGROS: "/Logros",
};

export const ANCLAS = {
  MAPA: "https://goo.gl/maps/FRwXTCRgxkHpkash6",
};
