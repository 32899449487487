import { useEffect } from "react";
import { Row, Container, Col, Card } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Title from "../TitleIzquierda";
import { logrosData } from "../../constants/logrosData";

import logro from "../../assets/images/logros.png";

import "../../styles/components/Logros.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 50 },
};

export default function Logros() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box Logro"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <Container>
        <Row>
          <Title titulo="Actividades Ejecutadas"></Title>
        </Row>
        <Row className="mt-3 mb-5 align-items-center">
          <Col xs={12} sm={6} md={6}>
            <img src={logro} alt="" width={"100%"} />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Row md={2} sm={2} xs={2} className="g-5 ms-3">
              {logrosData.map((data) => (
                <div key={data.id}>
                  <Col>
                    <Card border="0" className="cardTransparent">
                      <Card.Body>
                        <Card.Title className="titulo">
                          {data.titulo}
                        </Card.Title>
                        <Card.Text className="texto">{data.dato}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
